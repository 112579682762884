.navbar {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   padding: 0.75rem;
   /* box-shadow: 5px 1px 5px rgba(0, 0, 0, 0.3); */
   /* box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.15),
      0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 1px 3px 0 rgba(0, 0, 0, 0.12); */
   background: linear-gradient(to bottom, rgba(0, 0, 255, 0.1), white 100%),
      linear-gradient(to bottom, rgba(255, 0, 0, 0.05), rgba(255, 255, 0, 0.05));
}

.navbar .teleLink {
   border-bottom: 4px solid transparent;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: auto;
}

.navbar .teleLink:hover {
   border-bottom: 4px solid rgba(0, 0, 255, 0.1);
}

.navbar div {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   margin-bottom: auto;
}

.rightNav {
   justify-content: start;
   display: flex;
}

.titleContent {
   padding: 20px;
   z-index: 1;
   position: relative;
}

.title {
   font-weight: bold;
   font-size: 3rem;
   max-width: -moz-fit-content;
   max-width: fit-content;
   /* border-bottom: 5px solid rgba(0, 0, 255, 0.1); */
   position: relative;
}

.title-typing-text {
   display: inline-block;
   overflow: hidden;
   white-space: nowrap;
   animation: text-typing 1.2s steps(12, end) forwards;
   animation-fill-mode: forwards;
}

/* text typing animation */
@keyframes text-typing {
   from {
      width: 0;
   }

   to {
      width: 100%;
   }
}

.title-border-grow {
   border-bottom: 8px solid;
   border-color: transparent;
}

.title-border-grow.animate {
   animation: title-border-grow 0.2s steps(5, end) forwards;
}

@keyframes title-border-grow {
   0% {
      border-color: rgba(0, 0, 255, 0.001);
   }

   100% {
      border-color: rgba(0, 0, 255, 0.1);
   }
}

/* terminal cursor blink */
@keyframes cursor-blink {
   50% {
      background-color: rgba(0, 0, 255, 0.03);
   }
}

.terminal-cursor {
   display: inline-block;
   width: 1em;
   height: 2.5em;
   background-color: transparent;
   position: absolute;
   top: 50%;
   right: -2%;
   transform: translateY(-40%);
}

/* animation triggers once the .animate class is added to element with this class
   this lets us trigger the animation when we want, for instance when typing text finishes
*/
.terminal-cursor.animate {
   animation: cursor-blink 1s infinite steps(1);
}

#btnSchedule {
   background: #22C55E;
   display: flex;
   flex-direction: row;
   align-items: center;
}

#btnSchedule:hover {
   background: #16A34A;
}

#btnLogout {
   background: #EF4444;
}

#btnLogout:hover {
   background: #DC2626;
}

.logoutIcon {
   padding: 6px 15px;
   padding-top: 0px;
   padding-bottom: 16px;
   display: flex;
   position: relative;
   flex-direction: column;
}

.logoutText {
   font-size: 11px;
   width: 100%;
   position: absolute;
   top: 100%;
   left: 50%;
   transform: translate(-50%, -90%);
}

.profileIcon {
   background: var(--highlight);
   border-radius: 50%;
   padding: 0.4rem;
   padding-top: 0.2rem;
   font-size: 14px;
   text-align: center;
   margin-right: 5px;
}

.scheduleIcon {
   padding: 0.4rem;
   padding-top: 0.35rem;
}

.profileInfo {
   display: flex;
   flex-direction: column;
   justify-content: end;
   max-width: -moz-fit-content;
   max-width: fit-content;
}

.profileInfo span:first-of-type::after {
   content: '\25be';
   margin-left: 3px;
}

.profileInfo span:last-of-type {
   font-size: 9px;
}