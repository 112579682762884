.home-card {
   display: flex;
   flex-direction: column;
   justify-content: center;

   width: 75%;
   border-radius: 5px;
   margin: auto;
   
   background: linear-gradient(to right, rgba(0, 0, 255, 0.02), white 100%),
               linear-gradient(to bottom, rgba(255, 0, 0, 0.02), rgba(255, 255, 0, 0.02));

   box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.15),
         0 1px 1px 0 rgba(0, 0, 0, 0.14),
         0 1px 3px 0 rgba(0, 0, 0, 0.12);

   transition: 0.3s;
}

.home-card:hover {
   box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.2),
         0 1px 1px 0 rgba(0, 0, 0, 0.21),
         0 1px 3px 0 rgba(0, 0, 0, 0.17);

   background: linear-gradient(to right, rgba(0, 0, 255, 0.01), white 50%),
         linear-gradient(to bottom, rgba(255, 0, 0, 0.02), rgba(255, 255, 0, 0.03));
}

.home-card * {
   display: flex;
   justify-content: center;
}

.home-card-action-buttons {
   padding: 10px;
   margin-bottom: 10px;
}