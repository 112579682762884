.signup-card {
   display: flex;
   position: relative;
   flex-direction: column;
   justify-content: center;

   width: 75%;
   border-radius: 5px;
   margin: auto;

   background: linear-gradient(to right, rgba(0, 0, 255, 0.02), white 100%),
      linear-gradient(to bottom, rgba(255, 0, 0, 0.02), rgba(255, 255, 0, 0.02));

   box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.15),
      0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 1px 3px 0 rgba(0, 0, 0, 0.12);

   transition: 0.3s;
}

.signup-card:hover {
   box-shadow: 0 8px 5px -7px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.21),
      0 1px 3px 0 rgba(0, 0, 0, 0.17);

   background: linear-gradient(to right, rgba(0, 0, 255, 0.01), white 50%),
      linear-gradient(to bottom, rgba(255, 0, 0, 0.02), rgba(255, 255, 0, 0.03));
}

.signup-card * {
   display: flex;
   justify-content: center;
}

.back-button {
   position: absolute;
   top: 5%;
   left: 0;

   background-color: rgba(0, 0, 255, 0.25);
   border: none;
   padding: 3px 6px;
}

.form-group {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 350px;
   margin: auto;
}

.input-group,
.input-group *:not(label):not(small):not(i) {
   margin-top: 5px;
   display: flex;
   flex-direction: column;
   text-align: left;
   justify-content: left !important;
}

.input-group label {
   margin-top: 5px;
   justify-content: left;
}

.input-group small {
   justify-content: right;
   font-size: 11px;
}

.input-group-double {
   display: flex;
   flex-direction: row;
   margin-top: 10px;
   justify-content: space-between;
   text-align: center;
}

.input-group-double .input-group div {
   justify-content: right !important;
   text-align: right !important;
   margin: auto;
}

.submitBtnRow {
   margin-top: 35px;
}

.submitBtnRow button {
   background-color: #4CAF50;
}